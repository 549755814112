<template>
  <Title title="Barometre" :showBack="true" :showIcon="true"/>
  <div class="text-center wellbeing-form">
    <Criterion :displayNB="false" :editable="true" :criterias="meetingCriterias"/>
    <button class="btn-pink" @click="createMeetingCriteriasRate">Enregistrer</button>
  </div>
</template>

<script>
import Criterion from "../../components/BoostPack/Criterion";
import Title from "../../components/General/Title";
import AnonymousUsersService from "../../services/anonymousUsers.service";

export default {
    name: "MeetingCriterias",
    components: {Criterion, Title},
    data() {
        return {
            boostPack: Object,
            meetingCriterias: []
        };
    },
    created() {
        AnonymousUsersService.getCriteria(false, false, true).then((response) => {
            this.meetingCriterias = response.data.data.criteria.filter(x => x.type !== 'anon_stress_level_after')
        });
    },
    methods: {
        createMeetingCriteriasRate() {
          this.$store.state.user.barometer = this.meetingCriterias.filter(x => x.score !== null);
          this.$router.push({name: 'BoostPackMeeting'})
        }
    },
}
</script>

<style scoped>

</style>
